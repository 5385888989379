.fc-toolbar-chunk > div {
  display: flex;
}

.fc .fc-button-primary {
  /* color: #000 !important;
  color: var(--fc-button-text-color, #000) !important;
  background-color: #fff ; */
  /* background-color: #fff !important; */
  /* border-color: #fff !important; */
  /* border-color: #fff !important; */
  background-color: transparent !important;
  cursor: default !important;
}

.fc .fc-button {
  border-color: #fff !important;
  border: none !important;
  box-shadow: none !important;
}

.fc-theme-standard th {
  margin: 2px !important;
  border: none !important;
  background-color: #1292DC !important;
  color: #fff !important;
  /* padding-top: 4px !important; */
  /* padding-bottom: 4px !important; */
}

.fc-theme-standard th a {
  color: #fff !important;
  font-size: 12px !important;
  /* padding-top: 4px !important; */
  /* padding-bottom: 4px !important; */
}

/* button {
  border: none !important;
} */

/* .fc-CreateDate-button{
  background-color: #7030a0 !important;
  border-radius: 1rem !important;

} */

.fc-next-button,
.fc-prev-button {
  background-color: #fff !important;
  color: #000 !important;
}

.fc-daygrid-day {
  /* border-radius: 20px !important; */
  padding: 0px !important;
}
.fc-daygrid-day-frame {
  /* background-color: red !important; */
  border-radius: 3px !important;
  border: 1px solid #e3e3e3 !important;
}

.fc-theme-standard td {
  border: none !important;
}

.fc table {
  border: none !important;
}

.fc-daygrid-day-top {
  /* height: 80px; */
  font-size: 13px;
  justify-content: center;
  align-items:start;
}

/* .fc-daygrid-day-events {
  height: 20px;
 /* padding: 0 !important;
  background-color: #7030a0; */
/* } */

.fc-daygrid-day-bottom {
  padding: 0 !important;
  /* background-color: #7030a0; */
}
.fc-daygrid-day-events {
  display: flex;
  justify-content: space-between;
  min-height: 1rem !important;
  margin-top: 0px !important;
  padding-bottom: 4px !important; 
}
/* .fc-daygrid-event-harness{
 } */
.fc-event-title {
  padding-top: 3px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.fc-daygrid-event {
  border-radius: 50% !important;
  width: 15px !important;
  height: 15px !important;
  border: none !important;
  font-size: 9px !important;
}

.fc-day-today {
  background-color: transparent !important;
}

.fc-day-today .fc-daygrid-day-frame {
  /* background-color: #7030a0; */
  box-shadow: 0px 4px 10px rgba(104, 119, 175, 0.2);
}
.fc-header-toolbar{
  margin-bottom: 0px !important;
}
