@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-SemiBold.ttf");
  font-weight:600;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Bold.ttf");
  font-weight:700;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Open Sans, sans-serif;
}

:root {
  /* font-family: 'Poppins'; */
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  font-family: 'Poppins', Inter, system-ui, Avenir, Helvetica, Arial, sans-serif !important;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.rdt_TableBody{
  height: 68vh !important;
}


.dropdown:hover .dropdown-menu {
  width: 100px;
  display: block;
}

.dropdown:hover .dropdown-menuOne {
  width: 100px;
  display: block;
  list-style: none;
}

.dropdown:hover > .dropdown-content {
  width: 100%;
  display: block;
}

.model {
  transition: all 5s ease-in-out;
}


::-webkit-scrollbar {
  width: 6px;
  height: .5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(167, 163, 163); */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #97bdce;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0178ac;
}

._2pZMF{
  display: none !important;
} 


@media only screen and (max-width: 768px) {
  .my-modal {
    width: 100% !important; /* override the width of the modal */
  }
  .cus-ant-progress-inner{
    width: 80px !important;
    height: 80px !important;
  }
}


.dropdown:hover .dropdown-menu {
  display: block;
}

.ant-layout-header{
  padding-inline: 20px !important;
}

::-ms-reveal {
  display: none;
}



